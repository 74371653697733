import { useUserEmail } from '@/hooks/useUserEmail';
import { useTokenInfo } from '@/state/token';
import { api } from '@/trpc/react';
import { useSession } from 'next-auth/react';

export function useGetContact() {
  const { data: session } = useSession();
  const email = useUserEmail();
  const userWallet = useTokenInfo();

  const enabled = !!session && !!email && !!userWallet?.address;

  const { isLoading, data } = api.contacts.get.useQuery(
    { email: email || '' },
    { enabled },
  );

  return {
    isLoading,
    contact: data?.results[0],
  };
}

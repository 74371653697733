import { useCreateAppChainCustom } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainCustom';
import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainInfo';
import { useUserChainDataState } from '@/components/pages/CreateAppChain/state/hooks/useUserChainDataState';
import { useGenerateAllFiles } from '@/hooks/api/useGenerateAllFiles';
import { useGenerateGenesis } from '@/hooks/api/useGenerateGenesis';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useCreateAppchainFiles } from '@/hooks/useCreateAppchainFiles';
import type {
  ChainData,
  EvmChainData,
  SubstrateChainData,
} from '@/server/api/routers/file/file.interfaces';
import { useAddress } from '@/state/token';
import { enableProgressBar } from '@/utils/loading';
import { useCallback, useEffect } from 'react';
import { usePracticeGenerateFiles } from './polkadot/practice/usePracticeGenerateFiles';
import { useIsAppchainProtected } from './useIsAppchainProtected';

export function useGenerateFiles(): {
  generateFiles: () => void;
  isPending: boolean;
  isDisabled: boolean;
} {
  const address = useAddress();
  const { config } = useChainConfig();
  const { isPending: isPendingFiles, mutate: generateAllFiles } =
    useGenerateAllFiles();
  const { isPending: isPendingGenesis, mutate: generateGenesis } =
    useGenerateGenesis();
  const { chainData } = useUserChainDataState();
  const { isCustom } = useCreateAppChainCustom();
  const { paraId } = useCreateAppChainInfo();
  const { specRaw } = useCreateAppchainFiles();

  const { isProtected: isAppchainProtected, isLoading: isLoadingProtected } =
    useIsAppchainProtected();

  const { isLoading: isLoadingPractice, send: practiceGenerate } =
    usePracticeGenerateFiles();

  const generateFiles = useCallback(() => {
    if (!address || !paraId) return;

    if (chainData && !isCustom) {
      const specs = getSpecs(chainData);

      generateAllFiles({
        address,
        chainKey: config.key,
        orchestratorParaId: config.parachainId,
        userChainData: { ...specs, paraId },
      });
    }

    if (isCustom && specRaw) {
      generateGenesis({
        address,
        chainKey: config.key,
        specRaw: { ...specRaw, para_id: paraId },
      });
    }
  }, [
    address,
    paraId,
    chainData,
    isCustom,
    specRaw,
    config.key,
    config.parachainId,
    generateAllFiles,
    generateGenesis,
  ]);

  const isPending = isPendingFiles || isPendingGenesis;

  useEffect(() => {
    enableProgressBar(isPending);

    return () => enableProgressBar(false);
  }, [isPending]);

  return {
    generateFiles: isAppchainProtected ? generateFiles : practiceGenerate,
    isPending: isPending || isLoadingPractice,
    isDisabled:
      (isCustom && !specRaw) || (!isCustom && !chainData) || isLoadingProtected,
  };
}

type GenerateFileSpecs =
  | Omit<EvmChainData, 'category' | 'appchainUrl' | 'x'>
  | Omit<SubstrateChainData, 'category' | 'appchainUrl' | 'x'>;

function getSpecs(chainData: ChainData): GenerateFileSpecs {
  const { category, appchainUrl, x, ...specs } = chainData;
  return specs;
}

import {
  sidebarActiveTabAtom,
  sidebarAtom,
} from '@/components/Sidebar/state/sidebar.atoms';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback } from 'react';

export function useSidebar() {
  const [sidebar, setSidebar] = useAtom(sidebarAtom);
  const [activeTab, setActiveTab] = useAtom(sidebarActiveTabAtom);

  return {
    sidebar,
    tab: activeTab,
    open: setSidebar,
    close: useCallback(() => {
      setSidebar(RESET);
      setActiveTab(RESET);
    }, [setSidebar, setActiveTab]),
    setTab: setActiveTab,
  };
}

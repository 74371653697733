import { useCreateAppchainProtectedKey } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppchainProtectedKey';
import { ChainKey } from '@/config';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useAddress } from '@/state/token';
import { api } from '@/trpc/react';

export function useIsAppchainProtected() {
  const address = useAddress();
  const {
    config: { key },
  } = useChainConfig();
  const {
    protectedKey,
    hasProtectedKey,
    isLoading: isLoadingKey,
  } = useCreateAppchainProtectedKey();

  const { isLoading, data } = api.appchains.isProtected.useQuery(
    { protectedKey: protectedKey || '' },
    {
      enabled: hasProtectedKey && key === ChainKey.Flashbox && !!address,
      refetchOnWindowFocus: false,
    },
  );

  return {
    isProtected: key === ChainKey.Flashbox ? !!data : true,
    isLoading: isLoadingKey || isLoading,
  };
}

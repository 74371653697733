import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainInfo';
import { isProd } from '@/config';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useUserEmail } from '@/hooks/useUserEmail';
import { useAddress } from '@/state/token';
import { api } from '@/trpc/react';
import * as Sentry from '@sentry/nextjs';

export function useUpdateContact() {
  const email = useUserEmail();
  const address = useAddress();
  const { paraId } = useCreateAppChainInfo();
  const { config } = useChainConfig();

  const { isPending, mutate } = api.contacts.update.useMutation();

  return {
    isPending,
    updateContact: () => {
      if (!email || !paraId) {
        Sentry.captureMessage('Failed to update contact: missing data', {
          level: 'info',
          extra: { chain: config.name, address, paraId, email },
        });

        return;
      }

      if (isProd) {
        mutate({ email, chainKey: config.key, paraId });
      }
    },
  };
}

import { env } from '@/env.mjs';
import { useQuery } from '@tanstack/react-query';
import flagsmith from 'flagsmith/isomorphic';
import type { IState } from 'flagsmith/types';

const environmentID = env.NEXT_PUBLIC_FLAGSMITH_ENV_ID;

export async function getFlagsmith() {
  await flagsmith.init({ environmentID });

  return flagsmith;
}

export const useFlagsmithState = (): IState | undefined => {
  const { data } = useQuery({
    queryKey: ['flagsmith'],
    staleTime: 60 * 1000,
    refetchInterval: 60 * 1000,
    queryFn: async () => {
      const flagsmith = await getFlagsmith();

      return flagsmith.getState();
    },
  });

  return data;
};

import { Select, Text } from '@mantine/core';

import { ActionButton } from '@/components/ActionButton';
import { NextStepButton } from '@/components/pages/CreateAppChain/NextStepButton';
import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainInfo';
import { StepWrapper } from '@/components/pages/CreateAppChain/steps/StepWrapper';
import { useFreeUserParaIds, useReserveParaId } from '@/hooks/polkadot/common';
import { useCreateAppchainLogger } from '@/hooks/register/useCreateAppchainLogger';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useAddress } from '@/state/token';
import { IconCheck } from '@tabler/icons-react';

export function ReserveParaIdStep() {
  const { config, relay } = useChainConfig();
  const address = useAddress();
  const paraIds = useFreeUserParaIds(config);
  const { isReady, isLoading, send } = useReserveParaId(relay);
  const { logInfo } = useCreateAppchainLogger();

  const { paraId, reservedParaId, hasOwnParaId, setParaId } =
    useCreateAppChainInfo();
  const hasFreeParaId = !!paraIds?.length;
  const hasSelectedParaId = hasOwnParaId && !!paraId;
  const hasReservedParaId = !hasOwnParaId && !!reservedParaId;
  const selected = `You've Selected Network ID ${paraId}`;
  const reserved = `You've Reserved Network ID ${reservedParaId}`;
  const notReservedOrSelected = `${
    hasOwnParaId ? 'Select' : 'Reserve'
  } Network ID`;

  return (
    <StepWrapper>
      <StepWrapper.Title>
        {hasReservedParaId
          ? reserved
          : hasSelectedParaId
            ? selected
            : notReservedOrSelected}
      </StepWrapper.Title>

      <StepWrapper.Card>
        <Text size={'lg'} ta={'center'}>
          {
            'To function within the Tanssi environment, your network requires a unique ID, which can be secured through the execution of a transaction.'
          }
        </Text>
        <Text mt={'md'} size={'lg'} ta={'center'}>
          {
            'This ID is essential for registering your chain on the Relay Chain later on.'
          }
        </Text>
        <Text mt={'md'} size={'lg'} ta={'center'}>
          {
            'Please make sure to reserve the ID from the account you plan to use for launching your network.'
          }
        </Text>

        <StepWrapper.Buttons>
          {hasOwnParaId ? (
            <Select
              h={42}
              w={200}
              size={'md'}
              variant={'default'}
              placeholder={'Select Network ID'}
              checkIconPosition={'right'}
              allowDeselect={false}
              disabled={!hasFreeParaId}
              data={paraIds?.map((id) => id.paraId.toString())}
              value={paraId?.toString()}
              onChange={(value) => {
                if (value) {
                  setParaId(+value);
                  logInfo('Para ID selected', Step.ParaID, { paraId: +value });
                }
              }}
              styles={{
                input: {
                  backgroundColor: 'var(--mantine-color-dark-9)',
                  height: 45,
                  paddingLeft: 20,
                },
                dropdown: {
                  padding: 5,
                },
                option: {
                  padding: '5px 20px',
                },
              }}
            />
          ) : (
            <ActionButton
              isLoading={isLoading}
              disabled={!address || !!reservedParaId || !isReady}
              rightSection={
                reservedParaId && (
                  <IconCheck
                    size={20}
                    stroke={2}
                    color={'var(--mantine-color-tanssiTeal-9)'}
                  />
                )
              }
              onClick={() => send()}
            >
              {isLoading
                ? 'Reserving'
                : reservedParaId
                  ? 'Reserved'
                  : 'Reserve'}
            </ActionButton>
          )}
          {(hasSelectedParaId || hasReservedParaId) && <NextStepButton />}
        </StepWrapper.Buttons>
      </StepWrapper.Card>
    </StepWrapper>
  );
}

import { useCreateAppChainFiles } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainFiles';
import type { ChainKey } from '@/config';
import { useAddress } from '@/state/token';
import { api } from '@/trpc/react';

const day = 24 * 60 * 60 * 1000;

export function useChainFilesUrls(
  paraId: number | undefined,
  chainKey: ChainKey,
) {
  const address = useAddress();
  const { haveFiles } = useCreateAppChainFiles();

  return api.files.getChainUrls.useQuery(
    {
      address: address || '',
      chainKey,
      paraId: paraId || 0,
    },
    {
      enabled: !haveFiles && !!address && !!paraId,
      staleTime: day,
      refetchOnMount: 'always',
    },
  );
}

import type { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainInfo';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useTokenInfo } from '@/state/token';
import { captureMessage } from '@sentry/nextjs';
import { useMemo } from 'react';

interface Logger {
  logInfo: (
    message: string,
    step: Step,
    extra?: Record<string, unknown>,
  ) => void;
}

export function useCreateAppchainLogger(): Logger {
  const user = useTokenInfo();
  const { config, relay } = useChainConfig();
  const { paraId } = useCreateAppChainInfo();

  return useMemo(
    (): Logger => ({
      logInfo: (message: string, step: Step, extra) => {
        captureMessage(message, {
          level: 'info',
          tags: {
            step,
            process: 'register',
          },
          extra: {
            paraId,
            chain: config.name,
            relay: relay?.name,
            user,
            ...extra,
          },
        });
      },
    }),
    [user, config.name, paraId, relay],
  );
}

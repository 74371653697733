import type { BaseChainConfig } from '@/config';
import { useApi, useApiCall } from '@/hooks/polkadot';
import { transformProxies } from './transform';
import type { AccountProxy } from './useProxies.interfaces';

export function useProxies(
  address: string | undefined,
  config?: BaseChainConfig,
): AccountProxy[] | undefined {
  const api = useApi(config?.ws);

  const proxies = useApiCall(
    api?.query.proxy?.proxies,
    [address],
    transformProxies,
  );
  return proxies;
}

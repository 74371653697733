import { api } from '@/trpc/react';

export function useAppchainRuntimeVersions(template: string | undefined) {
  return api.appchains.getRuntimeVersions.useQuery(
    {
      template: template || '',
    },
    {
      enabled: !!template,
      refetchOnWindowFocus: false,
    },
  );
}

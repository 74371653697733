import { TEST_COLLATORS } from '@/components/pages/Staking/state/staking.constants';
import { isTest } from '@/config';
import { useAddress } from '@/state/token';
import { api } from '@/trpc/react';

export function useHistoricalCollators() {
  const address = useAddress();

  const { data } = api.staking.fetchHistoricalCollatorsList.useQuery(
    undefined,
    {
      enabled: !!address && !isTest,
    },
  );

  return { data: isTest ? TEST_COLLATORS : data };
}

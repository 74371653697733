import { type ChainConfig, isLocalChain } from '@/config';
import type { AppchainUrls } from '@/server/api/routers/appchains';
import { api } from '@/trpc/react';

const FIVE_MINUTES = 1000 * 60 * 5;

export function useAppchainUrls(
  paraId: number | undefined,
  config: ChainConfig | undefined,
): { urls: AppchainUrls | undefined; isLoading: boolean } {
  const { data, isLoading } = api.appchains.getUrls.useQuery(
    { paraId: paraId!, chainKey: config?.key! },
    {
      enabled: !!paraId && !!config?.key && !isLocalChain(config.key),
      staleTime: FIVE_MINUTES,
    },
  );

  return { urls: data, isLoading };
}

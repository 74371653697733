import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useSetAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback } from 'react';
import {
  activeStepAtom,
  chainDataStorageAtom,
  customFileAtom,
  genesisStateAtom,
  genesisWasmAtom,
  hasOwnParaIdStorageAtom,
  paraIdStorageAtom,
  reservedParaIdAtom,
  selectedTemplateAtom,
  specRawStorageAtom,
} from '../create.atoms';
import { useCreateAppChainSteps } from './useCreateAppChainSteps';

export function useCreateAppchainReset() {
  const { activeStep, getStepIndex } = useCreateAppChainSteps();

  const setSelectedTemplate = useSetAtom(selectedTemplateAtom);
  const setChainData = useSetAtom(chainDataStorageAtom);
  const setCustomFile = useSetAtom(customFileAtom);
  const setSpecRaw = useSetAtom(specRawStorageAtom);
  const setGenesisState = useSetAtom(genesisStateAtom);
  const setGenesisWasm = useSetAtom(genesisWasmAtom);
  const setReservedParaId = useSetAtom(reservedParaIdAtom);
  const setParaId = useSetAtom(paraIdStorageAtom);
  const setHasOwnParaId = useSetAtom(hasOwnParaIdStorageAtom);
  const setActiveStep = useSetAtom(activeStepAtom);

  const balancesStep = getStepIndex(Step.Balances);
  const step = activeStep > balancesStep ? balancesStep : activeStep;

  return useCallback(
    (isFullReset = true) => {
      setActiveStep(isFullReset ? RESET : step);
      setGenesisState(RESET);
      setGenesisWasm(RESET);
      setHasOwnParaId(RESET);
      setParaId(RESET);
      setReservedParaId(RESET);
      setSpecRaw(RESET);

      if (isFullReset) {
        setChainData(RESET);
        setCustomFile(RESET);
        setSelectedTemplate(RESET);
      }
    },
    [
      setActiveStep,
      setChainData,
      setCustomFile,
      setGenesisState,
      setGenesisWasm,
      setHasOwnParaId,
      setParaId,
      setReservedParaId,
      setSelectedTemplate,
      setSpecRaw,
      step,
    ],
  );
}

import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainInfo';
import { useCreateAppchainReset } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppchainReset';
import { useProcessPendingAppchain } from '@/hooks/api/useProcessPendingAppchain';
import { usePendingVerificationHttp } from '@/hooks/polkadot/common/queries/usePendingVerificationHttp';
import { useCreateAppchainLogger } from '@/hooks/register/useCreateAppchainLogger';
import { useChainConfig } from '@/hooks/useChainConfig';
import { api } from '@/trpc/react';
import { enableProgressBar } from '@/utils/loading';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export function useAppchainOnRegistered() {
  const { config } = useChainConfig();
  const trpcUtils = api.useUtils();
  const queryClient = useQueryClient();
  const { paraId } = useCreateAppChainInfo();
  const { replace } = useRouter();
  const resetCreateAppchain = useCreateAppchainReset();
  const { logInfo } = useCreateAppchainLogger();
  const { send: processAppchain } = useProcessPendingAppchain();
  const { refetch: isAppchainPending } = usePendingVerificationHttp(
    paraId,
    config,
  );

  return useCallback(async () => {
    enableProgressBar(false);

    await replace('/dashboard');
    await trpcUtils.files.getChainUrls.invalidate();
    await queryClient.invalidateQueries({
      queryKey: ['userAppchains', config.key],
    });

    const isPending = await isAppchainPending();

    if (isPending) {
      processAppchain();
      resetCreateAppchain();
    }

    logInfo('On registered action executed', Step.Register, { isPending });
  }, [
    config.key,
    queryClient,
    replace,
    logInfo,
    processAppchain,
    isAppchainPending,
    resetCreateAppchain,
    trpcUtils.files.getChainUrls.invalidate,
  ]);
}

import type { AllowedMimeType } from '@/server/api/routers/appchains/appchains.constants';
import {
  Box,
  Center,
  type CenterProps,
  Group,
  Loader,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { showNotification } from '@mantine/notifications';
import { IconUpload } from '@tabler/icons-react';
import { type ReactElement, useRef } from 'react';

interface AppchainIconEditorProps extends CenterProps {
  onUpdate: (
    iconImageBase64: string,
    fileType: AllowedMimeType,
  ) => Promise<void> | void;
  IconComponent: ReactElement;
  isPending?: boolean;
}

export function AppchainIconEditor({
  onUpdate,
  IconComponent,
  isPending = false,
  ...wrapperProps
}: AppchainIconEditorProps) {
  const openRef = useRef<() => void>(null);
  const theme = useMantineTheme();

  if (isPending) {
    return <Loader color={theme.colors.tanssiTeal[9]} />;
  }

  return (
    <Group>
      <Dropzone
        openRef={openRef}
        onDrop={([file]) => {
          file.arrayBuffer().then(async (buf) => {
            const iconImageBase64 = window.btoa(
              String.fromCharCode(...new Uint8Array(buf)),
            );

            await onUpdate(iconImageBase64, file.type as AllowedMimeType);
          });
        }}
        onReject={([fileRejection]) => {
          const [error] = fileRejection.errors;

          const errorMessage =
            error.code === 'file-too-large'
              ? 'File is larger than 100 KB'
              : 'Unknown error';

          showNotification({
            message: errorMessage,
            color: 'red',
          });
        }}
        accept={[MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.webp]}
        maxFiles={1}
        multiple={false}
        maxSize={0.1 * 1024 ** 2}
        display={'none'}
      />

      <UnstyledButton onClick={() => openRef.current?.()}>
        <Center {...wrapperProps}>
          {IconComponent}
          <Box
            pos={'absolute'}
            right={-8}
            bottom={-10}
            bg={'dark.6'}
            w={25}
            h={25}
            style={{
              border: '3px solid var(--mantine-color-dark-8)',
              borderRadius: '50%',
            }}
          >
            <IconUpload
              size={15}
              stroke={2}
              color={'rgba(255, 255, 255, 0.3)'}
              style={{ marginLeft: 2, marginBottom: 2 }}
            />
          </Box>
        </Center>
      </UnstyledButton>
    </Group>
  );
}

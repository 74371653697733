import type { ChainConfig } from '@/config';
import { useApiHttp } from '@/hooks/polkadot/useApiHttp';
import type { StorageKey } from '@polkadot/types';
import type { Option } from '@polkadot/types-codec';
import type { AccountId32 } from '@polkadot/types/interfaces';
import type { PalletRegistrarDepositInfo } from '@polkadot/types/lookup';
import { type UseQueryResult, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useRegisteredParaIds } from '../../common';
import { useApi } from '../../useApi';
import { useApiCall } from '../../useApiCall';

interface AppchainInfo {
  paraId: number;
  creator: string;
}

type DepositInfoEntries = Array<
  [StorageKey, Option<PalletRegistrarDepositInfo>]
>;

export function transformRegistrar(
  entries: DepositInfoEntries,
): AppchainInfo[] {
  return entries.map(([key, info]) => {
    const paraId = Number((key.toHuman() as string[]).at(0)?.replace(',', ''));

    return {
      paraId,
      creator: info.unwrapOrDefault().creator.toString(),
    };
  });
}

export function useAllAppchains(
  config: ChainConfig,
): UseQueryResult<AppchainInfo[] | undefined, unknown> {
  const api = useApiHttp(config.http);

  return useQuery({
    queryKey: ['userAppchains', config.key],
    queryFn: async () => {
      const res =
        await api?.query[config.pallets.registrar].registrarDeposit.entries();

      return res ? transformRegistrar(res as DepositInfoEntries) : undefined;
    },
    enabled: !!api,
  });
}

export function useActiveAppchains(
  config: ChainConfig,
): AppchainInfo[] | undefined {
  const active = useRegisteredParaIds(config);
  const { data: all } = useAllAppchains(config);

  return useMemo(
    () => all?.filter((appchain) => active?.includes(appchain.paraId)),
    [active, all],
  );
}

export function useAppchainManager(paraId: number, config: ChainConfig) {
  const api = useApi(config.ws);

  return useApiCall(
    api?.query[config.pallets.registrar].paraManager,
    [paraId],
    transformAppchainManager,
  );
}

export function transformAppchainManager(data: Option<AccountId32>) {
  return data.toHuman();
}

import type { Appchain, PublicAppchain } from './appchains.interfaces';

export const PUBLIC_APPCHAIN_KEYS = [
  'isEvm',
  'runtime',
  'url',
  'symbol',
  'evmChainId',
  'paraId',
  'name',
  'chainKey',
  'logo',
  'decimals',
  'category',
] satisfies (keyof Appchain)[];

export const PUBLIC_APPCHAIN_COLUMNS = PUBLIC_APPCHAIN_KEYS.reduce(
  (acc, key) => {
    acc[key] = true;

    return acc;
  },
  {} as Record<keyof PublicAppchain, true>,
);

import { usePracticeCreateAppchain } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppchainProtectedKey';
import type { RelayChainConfig } from '@/config';
import { useParaLifecycles } from '@/hooks/polkadot/common/queries/useParaLifecycles';
import { useIsAppchainProtected } from '@/hooks/useIsAppchainProtected';

export function useIsRegisteredInRelay(config: RelayChainConfig | undefined): {
  isRegistered: boolean;
  isLoading: boolean;
} {
  const { isProtected, isLoading } = useIsAppchainProtected();
  const { isLoading: isLoadingPracticeCreateAppchain, isRegisteredInRelay } =
    usePracticeCreateAppchain();
  const data = useParaLifecycles(config);

  if (!isProtected) {
    return {
      isLoading: isLoading || isLoadingPracticeCreateAppchain,
      isRegistered: isRegisteredInRelay,
    };
  }

  return {
    isLoading: data === undefined,
    isRegistered: !!data,
  };
}

import {
  pgTable,
  serial,
  timestamp,
  uniqueIndex,
  varchar,
} from 'drizzle-orm/pg-core';
import { createInsertSchema, createSelectSchema } from 'drizzle-zod';

export const users = pgTable(
  'users',
  {
    id: serial('id').primaryKey(),
    createdAt: timestamp('created_at').notNull().defaultNow(),
    updatedAt: timestamp('updated_at').notNull().defaultNow(),
    address: varchar('address').notNull(),
    name: varchar('name'),
    email: varchar('email'),
    telegram: varchar('telegram'),
  },
  (table) => [uniqueIndex('address_idx').on(table.address)],
);

export const selectUserSchema = createSelectSchema(users, {
  name: (schema) => schema.name.min(2).optional(),
  email: (schema) => schema.email.email().optional(),
  telegram: (schema) => schema.telegram.min(4).optional(),
});

export const insertUserSchema = createInsertSchema(users, {
  name: (schema) => schema.name.min(2).optional(),
  email: (schema) => schema.email.email().optional(),
  telegram: (schema) => schema.telegram.min(4).optional(),
});

export type User = typeof users.$inferSelect;
export type InsertUser = Omit<
  typeof users.$inferInsert,
  'id' | 'createdAt' | 'updatedAt'
>;

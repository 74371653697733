import { useAtom, useAtomValue } from 'jotai';
import { type CustomFile, customFileAtom, isCustomAtom } from '../create.atoms';

export interface CreateAppChainCustomState {
  isCustom: boolean;
  file: CustomFile | undefined;
  setFile: (file: CustomFile | undefined) => void;
}

export function useCreateAppChainCustom(): CreateAppChainCustomState {
  const isCustom = useAtomValue(isCustomAtom);
  const [file, setFile] = useAtom(customFileAtom);

  return {
    isCustom,
    file,
    setFile,
  };
}

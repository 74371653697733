import { useAtom, useAtomValue } from 'jotai';
import {
  hasOwnParaIdAtom,
  paraIdAtom,
  reservedParaIdAtom,
} from '../create.atoms';

interface ChainInfo {
  reservedParaId: number | undefined;
  hasOwnParaId: boolean;
  setHasOwnParaId: (hasOwnParaId: boolean) => void;
  paraId: number | undefined;
  setParaId: (paraId: number) => void;
}

export function useCreateAppChainInfo(): ChainInfo {
  const [paraId, setParaId] = useAtom(paraIdAtom);
  const reservedParaId = useAtomValue(reservedParaIdAtom);
  const [hasOwnParaId, setHasOwnParaId] = useAtom(hasOwnParaIdAtom);

  return {
    reservedParaId,
    hasOwnParaId,
    setHasOwnParaId,
    paraId,
    setParaId,
  };
}

import {
  SidebarButton,
  SidebarHeader,
  SidebarStack,
} from '@/components/Sidebar';
import { useIsRuntimeUpgradeEnabledFlag } from '@/hooks/flags/useIsRuntimeUpgradeEnabledFlag';
import { ScrollArea } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { useManageAppchain } from '../state';
import {
  ManageAppchainTab,
  getTabInfo,
} from '../state/ManageAppchain.constants';

const buttons = [
  ManageAppchainTab.TokenManagement,
  ManageAppchainTab.BlockProduction,
  ManageAppchainTab.Xcm,
  ManageAppchainTab.Maintenance,
  ManageAppchainTab.Explorers,
  ManageAppchainTab.Indexers,
  ManageAppchainTab.Oracles,
  ManageAppchainTab.Bridges,
  ManageAppchainTab.AppchainStaking,
].map(getTabInfo);

export function Management() {
  const { switchAppchainSidebarTab } = useManageAppchain();
  const { height } = useViewportSize();

  const { isEnabled: isRuntimeUpgradeEnabled } =
    useIsRuntimeUpgradeEnabledFlag();

  return (
    <>
      <SidebarHeader title={getTabInfo(ManageAppchainTab.Management).title} />

      <ScrollArea.Autosize type={'never'} mah={height - 65}>
        <SidebarStack>
          {buttons.map(({ title, description, tab, isDisabled }) => {
            const isMaintenanceDisabled =
              tab === ManageAppchainTab.Maintenance && !isRuntimeUpgradeEnabled;

            const isButtonDisabled = isDisabled || isMaintenanceDisabled;

            return (
              <SidebarButton
                key={title}
                onClick={() => switchAppchainSidebarTab(tab)}
                title={title}
                description={description}
                isDisabled={isButtonDisabled}
                badgeText={isButtonDisabled ? 'Coming soon' : undefined}
              />
            );
          })}
        </SidebarStack>
      </ScrollArea.Autosize>
    </>
  );
}

import { useManageAppchainState } from '@/components/pages/Dashboard/ManageAppchain/state';
import { ChainKey } from '@/config';
import {
  useAppchainRuntimeInfo,
  useAppchainSudo,
} from '@/hooks/polkadot/appchain';
import { api } from '@/trpc/react';

export function useAppchainNextRuntimeWasm() {
  const { sudoKey } = useAppchainSudo();
  const { paraId, config } = useManageAppchainState();
  const runtime = useAppchainRuntimeInfo(paraId, config);

  return api.appchains.getRuntimeWasm.useQuery(
    {
      address: sudoKey || '',
      chainKey: config?.key || ChainKey.Dancebox,
      paraId: paraId || 0,
      template: runtime?.template || '',
      version: runtime?.version.next || 0,
    },
    { enabled: false },
  );
}

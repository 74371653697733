import { ApiPromise, HttpProvider } from '@polkadot/api';
import { typesBundle } from '@polkadot/apps-config';
import type { EventRecord } from '@polkadot/types/interfaces/system';
import type { ISubmittableResult } from '@polkadot/types/types';

export interface SuccessCallbackParams {
  txHash: string;
  blockHash: string;
}

export interface ErrorCallbackParams {
  error: Error;
  params?: unknown[];
  txHash?: string;
  blockHash?: string;
}

export interface TxEventCb {
  onSuccess?: (params: SuccessCallbackParams) => void;
  onEvents?: ((events: EventRecord[]) => void) | undefined;
  onLoading?: (enabled: boolean) => void;
  onError?: (params: ErrorCallbackParams) => void;
}

export function createTxEventHandler({
  onSuccess,
  onEvents,
  onLoading,
  onError,
}: TxEventCb) {
  return ({
    status,
    events,
    dispatchError,
    txHash,
  }: ISubmittableResult): void => {
    if (onLoading) {
      if (status.isBroadcast) onLoading(true);
      if (status.isFinalized) onLoading(false);
    }

    onEvents?.(events);

    events.forEach(({ event: { method, section }, registry }) => {
      if (!status.isFinalized) return;

      const blockHash = status.asFinalized.toHex();

      if (section !== 'system') return;

      if (method === 'ExtrinsicFailed' && dispatchError) {
        let errorMessage: string;

        if (dispatchError.isModule) {
          const { docs, name, section } = registry.findMetaError(
            dispatchError.asModule,
          );

          errorMessage = `${section}.${name}: ${docs.join(' ')}`;
        } else {
          errorMessage = dispatchError.toString();
        }

        onError?.({
          error: new Error(errorMessage),
          txHash: txHash.toHex(),
          blockHash,
        });
      }

      if (method === 'ExtrinsicSuccess') {
        onSuccess?.({ txHash: txHash.toHex(), blockHash });
      }
    });
  };
}

export async function getPolkadotHttpApi(
  endpoint: string,
): Promise<ApiPromise> {
  const promise = ApiPromise.create({
    noInitWarn: true,
    provider: new HttpProvider(endpoint),
    typesBundle,
  });
  const api = await promise;

  await api.isReady;

  return api;
}

export async function isNetworkUp(http: string) {
  try {
    const response = await fetch(http, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: 1,
        jsonrpc: '2.0',
        method: 'chain_getHeader',
        params: [],
      }),
    });

    return response.ok;
  } catch {
    return false;
  }
}

import { TEST_COLLATORS } from '@/components/pages/Staking/state/staking.constants';
import { isTest } from '@/config';
import type { ApiPromise } from '@polkadot/api';
import { getEligibleCandidates } from './getEligibleCandidates';
import { getInvulnerables } from './getInvulnerables';

export async function getAllCollatorsAddresses(
  api: ApiPromise,
): Promise<string[] | undefined> {
  const [eligible, invulnerables] = await Promise.all([
    getEligibleCandidates(api),
    getInvulnerables(api),
  ]);

  if (!eligible || !invulnerables) {
    return;
  }

  return [...eligible, ...invulnerables].filter(forTests).sort();
}

function forTests(address: string) {
  return !isTest || TEST_COLLATORS.includes(address);
}

import type { SpecRaw } from '@/server/api/routers/file/file.interfaces';
import { useAtom } from 'jotai';
import {
  genesisStateAtom,
  genesisWasmAtom,
  specRawAtom,
} from '../create.atoms';

export interface ChainFiles {
  specRaw: SpecRaw | undefined;
  setSpecRaw: (specRaw: SpecRaw) => void;
  genesisState: string | undefined;
  setGenesisState: (genesis: string) => void;
  genesisWasm: string | undefined;
  setGenesisWasm: (wasm: string) => void;
  haveFiles: boolean;
}

export function useCreateAppChainFiles(): ChainFiles {
  const [specRaw, setSpecRaw] = useAtom(specRawAtom);
  const [genesisWasm, setGenesisWasm] = useAtom(genesisWasmAtom);
  const [genesisState, setGenesisState] = useAtom(genesisStateAtom);

  const haveFiles = !!specRaw && !!genesisWasm && !!genesisState;

  return {
    specRaw,
    setSpecRaw,
    genesisWasm,
    setGenesisWasm,
    genesisState,
    setGenesisState,
    haveFiles,
  };
}

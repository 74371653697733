import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useCreateAppChainFiles } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainFiles';
import { useCreateAppchainLogger } from '@/hooks/register/useCreateAppchainLogger';
import { api } from '@/trpc/react';

export function useGenerateGenesis() {
  const { logInfo } = useCreateAppchainLogger();
  const { setGenesisState, setGenesisWasm, haveFiles } =
    useCreateAppChainFiles();

  return api.files.generateGenesis.useMutation({
    onSuccess(data) {
      setGenesisState(data.genesisState);
      setGenesisWasm(data.genesisWasm);

      logInfo(
        `Genesis files were successfully ${haveFiles ? 're' : ''}generated.`,
        Step.Files,
      );
    },
  });
}

import type { ChainKey } from '@/config';
import { useRegisteredAppchains } from '@/hooks/api/useRegisteredAppchains';
import { AppchainCategory } from '@/shared/db/entities/appchains';
import { useMemo } from 'react';

export function useOpenableChannelsAppchains(
  chainKey: ChainKey | undefined,
  paraIds: number[] | undefined,
) {
  const { data: appchains, isLoading: isAppchainsLoading } =
    useRegisteredAppchains(chainKey);

  const memoizedAppchains = useMemo(() => {
    if (isAppchainsLoading || !appchains || !paraIds || !chainKey) {
      return appchains;
    }

    const paraIdsWithAppchains = appchains.map((appchain) => appchain.paraId);
    const parachainsWithoutAppchain = paraIds
      .filter((paraId) => !paraIdsWithAppchains.includes(paraId))
      .map((paraId) => ({
        chainKey,
        paraId,
        name: paraId.toString(),
        evmChainId: null,
        runtime: null,
        logo: null,
        url: null,
        isEvm: false,
        symbol: null,
        decimals: null,
        category: AppchainCategory.Other,
      }));

    return appchains.concat(parachainsWithoutAppchain);
  }, [appchains, paraIds, isAppchainsLoading, chainKey]);

  return { appchains: memoizedAppchains, isLoading: isAppchainsLoading };
}

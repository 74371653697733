import { useCreateAppChainFiles } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainFiles';
import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainInfo';
import { usePracticeCreateAppchain } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppchainProtectedKey';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useDownloadedFiles } from '@/hooks/useDownloadedFiles';
import type { Files } from '@/server/api/routers/file/file.interfaces';

export interface DownloadedFiles extends Partial<Files> {
  haveFiles: boolean;
  isFilesExistenceConfirmed: boolean;
}

export function useCreateAppchainFiles(): DownloadedFiles {
  const { config } = useChainConfig();
  const { paraId } = useCreateAppChainInfo();

  const { specRaw, genesisState, genesisWasm, haveFiles } =
    useCreateAppChainFiles();
  const { isLoading, areFilesGenerated } = usePracticeCreateAppchain();

  const {
    data,
    isFetched: isFetchedFiles,
    isSuccess,
    isFetchedUrls,
    shouldFetchFiles,
  } = useDownloadedFiles(paraId, config.key);

  return {
    isFilesExistenceConfirmed:
      (isFetchedUrls && !shouldFetchFiles) ||
      isFetchedFiles ||
      haveFiles ||
      (!isLoading && areFilesGenerated),
    haveFiles: haveFiles || isSuccess || areFilesGenerated,
    specRaw: specRaw || data?.specRaw,
    genesisState: genesisState || data?.genesisState,
    genesisWasm: genesisWasm || data?.genesisWasm,
  };
}

import { SidebarHeader, SidebarStack } from '@/components/Sidebar';
import { SidebarOffsiteLink } from '@/components/Sidebar/SidebarOffsiteLink';
import { useManageAppchainState } from '@/components/pages/Dashboard/ManageAppchain/state';
import { EXPLORERS } from '@/config';
import { useAppchainUrls } from '@/hooks/polkadot/appchain';
import {
  ManageAppchainTab,
  getTabInfo,
} from '../state/ManageAppchain.constants';

export function Explorers() {
  const { paraId, config } = useManageAppchainState();
  const { urls } = useAppchainUrls(paraId, config);

  if (!urls) {
    return null;
  }

  return (
    <>
      <SidebarHeader
        title={getTabInfo(ManageAppchainTab.Explorers).title}
        goBack={ManageAppchainTab.Management}
      />

      <SidebarStack>
        {urls.explorers.map(({ name, description, logo, url }) => (
          <SidebarOffsiteLink
            key={name}
            name={name}
            description={description || ''}
            url={url}
            logo={logo || ''}
          />
        ))}
        <SidebarOffsiteLink
          name={EXPLORERS.blockscoutDeployer.name}
          description={EXPLORERS.blockscoutDeployer.description}
          url={EXPLORERS.blockscoutDeployer.url}
          logo={EXPLORERS.blockscoutDeployer.logo}
        />
      </SidebarStack>
    </>
  );
}

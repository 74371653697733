import type { ChainKey } from '@/config';
import { api } from '@/trpc/react';

export function useRegisteredAppchains(chainKey: ChainKey | undefined) {
  return api.appchains.getRegisteredAppchains.useQuery(
    { chainKey: chainKey as ChainKey },
    {
      enabled: !!chainKey,
    },
  );
}

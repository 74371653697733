import {
  boolean,
  integer,
  pgEnum,
  pgTable,
  serial,
  timestamp,
  uniqueIndex,
  varchar,
} from 'drizzle-orm/pg-core';
import { createInsertSchema, createSelectSchema } from 'drizzle-zod';
import type { UnionToTuple } from 'type-fest';
import { chainKeyEnum } from '../common.schema';
import { users } from '../users/users.schema';

export enum AppchainCategory {
  AI = 'AI',
  DeFi = 'DeFi & RWA',
  Enterprise = 'Enterprise',
  Gaming = 'Gaming',
  Governance = 'Governance & Utility',
  Infrastructure = 'Infrastructure',
  NFT = 'NFT',
  Web3Social = 'Web 3 & Social',
  Other = 'Other',
}

export const APPCHAIN_CATEGORIES = Object.values(
  AppchainCategory,
) as UnionToTuple<AppchainCategory>;
export const categoryEnum = pgEnum('category', APPCHAIN_CATEGORIES);

export const appchains = pgTable(
  'appchains',
  {
    id: serial('id').primaryKey(),
    createdAt: timestamp('created_at').notNull().defaultNow(),
    updatedAt: timestamp('updated_at').notNull().defaultNow(),
    chainKey: chainKeyEnum('chain_key').notNull(),
    paraId: integer('para_id').notNull(),
    symbol: varchar('symbol'),
    decimals: integer('decimals'),
    evmChainId: varchar('evm_chain_id'),
    runtime: varchar('runtime'),
    name: varchar('name'),
    category: categoryEnum('category')
      .default(AppchainCategory.Other)
      .notNull(),
    logo: varchar('logo'),
    url: varchar('url'),
    x: varchar('x'),
    isEvm: boolean('is_evm').notNull().default(false),
    isPending: boolean('is_pending').notNull().default(true),
    // all registered but also running (approved to be running)
    isActive: boolean('is_active').notNull().default(false),
    // all registered appchains including active and non active
    isRegistered: boolean('is_registered').notNull().default(true),
    isLaunchedEmailSent: boolean('is_launched_email_sent')
      .notNull()
      .default(false),
    isDestroyedEmailSent: boolean('is_destroyed_email_sent')
      .notNull()
      .default(false),
    userId: integer('user_id')
      .references(() => users.id)
      .notNull(),
  },
  (table) => [
    uniqueIndex('chain_key_para_id_idx').on(table.chainKey, table.paraId),
  ],
);

export const selectAppchainSchema = createSelectSchema(appchains, {
  paraId: (schema) => schema.paraId.int().positive(),
  url: (schema) => schema.url.url().optional(),
  logo: (schema) => schema.logo.url().optional(),
});

export const insertAppchainSchema = createInsertSchema(appchains, {
  paraId: (schema) => schema.paraId.int().positive(),
  url: (schema) => schema.url.url().optional(),
  logo: (schema) => schema.logo.url().optional(),
});

import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useIsMounted } from '@/hooks/useIsMounted';
import { useAddress } from '@/state/token';
import { useAtom } from 'jotai';
import { useRouter } from 'next/navigation';
import { useCallback } from 'react';
import { activeStepAtom } from '../create.atoms';
import { STEPS } from '../create.constants';

export interface CreateAppChainState {
  activeStep: number;
  setActiveStep: (page: number) => void;
  getStepIndex: (step: Step) => number;
  getStepComponent: () => JSX.Element | undefined;
  nextStep: VoidFunction;
  prevStep: VoidFunction;
  onCreateAppchain: VoidFunction;
}

export function useCreateAppChainSteps(): CreateAppChainState {
  const address = useAddress();
  const router = useRouter();
  const isMounted = useIsMounted();
  const [activeStep, setActiveStep] = useAtom(activeStepAtom);

  const getStepIndex = useCallback((step: Step): number => {
    return STEPS.findIndex((s) => s.name === step);
  }, []);

  const getStepComponent = useCallback((): JSX.Element | undefined => {
    if (isMounted) {
      return STEPS[activeStep].component;
    }
  }, [isMounted, activeStep]);

  const nextStep = useCallback((): void => {
    const lastStep = STEPS.length - 1;

    setActiveStep((current) => (current < lastStep ? current + 1 : current));
  }, [setActiveStep]);

  const prevStep = useCallback((): void => {
    activeStep > 0 ? setActiveStep((current) => current - 1) : router.push('/');
  }, [activeStep, router, setActiveStep]);

  const onCreateAppchain = useCallback((): void => {
    setActiveStep(address ? activeStep : getStepIndex(Step.Template));
  }, [activeStep, address, getStepIndex, setActiveStep]);

  return {
    activeStep,
    setActiveStep,
    nextStep,
    prevStep,
    getStepIndex,
    getStepComponent,
    onCreateAppchain,
  };
}

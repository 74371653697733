import type { ChainConfig } from '@/config';
import { useApi } from '@/hooks/polkadot';
import { useIsAppchainUp } from '@/hooks/polkadot/appchain/queries/useIsAppchainUp';
import { useAppchainUrls } from '@/hooks/polkadot/appchain/useAppchainUrls';
import { useRegisteredParaIds } from '@/hooks/polkadot/common/queries/useRegisteredParaIds';
import type { ApiPromise } from '@polkadot/api';

export function useAppchainApi(
  paraId: number | undefined,
  config: ChainConfig | undefined,
): ApiPromise | undefined {
  const registeredParaIds = useRegisteredParaIds(config);
  const { isUp } = useIsAppchainUp(paraId, config);
  const { urls } = useAppchainUrls(paraId, config);

  const ws =
    paraId && registeredParaIds?.includes(paraId) && urls ? urls.ws : undefined;

  return useApi(isUp ? ws : undefined);
}

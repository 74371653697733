import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainInfo';
import type { ChainConfig } from '@/config';
import { useCreateAppchain } from '@/hooks/api/useCreateAppchain';
import { useApiNotifications } from '@/hooks/notifications/useApiNotifications';
import { useWalletNotifications } from '@/hooks/notifications/useWalletNotifications';
import { useApi, useApiTransaction } from '@/hooks/polkadot';
import { useCreateAppchainLogger } from '@/hooks/register/useCreateAppchainLogger';
import { useAppchainOnRegistered } from '@/hooks/useAppchainOnRegistered';
import { useCreateAppchainFiles } from '@/hooks/useCreateAppchainFiles';
import { useTanssiGenesis } from '@/hooks/useGenerateTanssiGenesis';
import { useIsAppchainProtected } from '@/hooks/useIsAppchainProtected';
import { useTimeout } from '@mantine/hooks';
import { usePracticeRegisterInTanssi } from '../../practice/usePracticeRegisterInTanssi';

const WAITING_TIME = 90 * 1_000;

interface Register {
  isLoading: boolean;
  isReadyToRegister: boolean;
  onRegister: VoidFunction;
}

export function useRegisterInTanssi(config: ChainConfig): Register {
  const api = useApi(config.ws);
  const genesisData = useTanssiGenesis(config);
  const { paraId } = useCreateAppChainInfo();
  const onRegistered = useAppchainOnRegistered();
  const { logInfo } = useCreateAppchainLogger();
  const { clientError } = useApiNotifications();
  const { createAppchainItem } = useCreateAppchain();
  const { transactionSuccess } = useWalletNotifications(config);
  const { genesisState } = useCreateAppchainFiles();

  const { start, clear } = useTimeout(async () => {
    onRegistered();
    logInfo('Timeout called when registering in Tanssi', Step.Register);
  }, WAITING_TIME);

  const { isProtected: isAppchainProtected, isLoading: isLoadingProtected } =
    useIsAppchainProtected();

  const { isLoading: isLoadingMock, send: sendMock } =
    usePracticeRegisterInTanssi(config);

  const { isLoading, send } = useApiTransaction({
    tx: api?.tx[config.pallets.registrar].register,
    config,
    notify: { notifyOnSuccess: false },
    onSuccess: (txHash?: string, blockHash?: string) => {
      clear();
      createAppchainItem();
      transactionSuccess({
        txHash,
        blockHash,
        title: 'Network was successfully registered',
        message: 'Network was successfully registered in Tanssi Chain.',
      });
      onRegistered();
    },
    onLoading: (enabled: boolean) => {
      if (enabled) {
        // do stuff when tx is broadcasted
        start();
      }
    },
  });

  return {
    isLoading: isLoading || isLoadingMock,
    isReadyToRegister:
      !!genesisData || (!isLoadingProtected && !isAppchainProtected),
    onRegister: () => {
      if (!isAppchainProtected && !isLoadingProtected) {
        sendMock();

        return;
      }

      if (!paraId || !genesisData) {
        clientError('Failed to register network due to missing para id', {
          paraId,
          chain: config.name,
        });

        return;
      }

      if (config.isSymbioticChain) {
        send(paraId, genesisData, genesisState);
      } else {
        send(paraId, genesisData, null);
      }
    },
  };
}

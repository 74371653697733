import type { Appchain } from '@/shared/db/entities/appchains';
import { useAddress } from '@/state/token';
import { api } from '@/trpc/react';
import { useDidUpdate } from '@mantine/hooks';

export function useUserAppchains(): {
  isLoading: boolean;
  appchains: Appchain[] | undefined;
} {
  const address = useAddress();
  const { isLoading, data, refetch } = api.appchains.getUserAppchains.useQuery(
    undefined,
    {
      enabled: !!address,
    },
  );

  useDidUpdate(() => {
    if (address) refetch();
  }, [address]);

  return {
    isLoading,
    appchains: data,
  };
}

import { useIsMounted } from '@/hooks/useIsMounted';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import {
  practiceCreateAppchainAtom,
  protectedKeyStorageAtom,
} from '../create.atoms';

export interface CreateAppchainProtected {
  protectedKey: string | undefined;
  hasProtectedKey: boolean;
  isLoading: boolean;
}

export function useCreateAppchainProtectedKey(): CreateAppchainProtected {
  const searchParams = useSearchParams();
  const [protectedKey, setProtectedKey] = useAtom(protectedKeyStorageAtom);
  const isMounted = useIsMounted();

  useEffect(() => {
    const key = searchParams?.get('protectedKey');

    if (key && key !== protectedKey) {
      setProtectedKey(key);
    }
  }, [searchParams, protectedKey, setProtectedKey]);

  return {
    protectedKey,
    hasProtectedKey: !!protectedKey,
    isLoading: !isMounted,
  };
}

export function usePracticeCreateAppchain() {
  const isMounted = useIsMounted();
  const { isRegisteredInRelay, isRegisteredInTanssi, areFilesGenerated } =
    useAtomValue(practiceCreateAppchainAtom);
  const setPracticeCreateAppchain = useSetAtom(practiceCreateAppchainAtom);

  return {
    isLoading: !isMounted,
    areFilesGenerated,
    isRegisteredInRelay,
    isRegisteredInTanssi,
    setPracticeCreateAppchain,
  };
}

import '@polkadot/api-augment';

import { usePracticeCreateAppchain } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppchainProtectedKey';
import { useUserChainDataState } from '@/components/pages/CreateAppChain/state/hooks/useUserChainDataState';
import type { RelayChainConfig } from '@/config';
import { useApi } from '@/hooks/polkadot';
import { useApiBatchTransaction } from '@/hooks/polkadot/useApiBatchTransaction';
import { useAddress } from '@/state/token';
import { showNotification } from '@mantine/notifications';
import { usePracticeAppchainId } from './usePracticeAppchainId';

export function usePracticeRegisterInRelay(
  config: RelayChainConfig | undefined,
) {
  const api = useApi(config?.ws);
  const address = useAddress();
  const id = usePracticeAppchainId();
  const { setPracticeCreateAppchain } = usePracticeCreateAppchain();
  const { chainData } = useUserChainDataState();

  const { isLoading, send } = useApiBatchTransaction(config, {
    onSuccess: () => {
      setPracticeCreateAppchain({ isRegisteredInRelay: true });
      showNotification({
        title: 'Appchain was successfully registered',
        message: 'Appchain was successfully registered in the Relay Chain.',
        color: 'tanssiBlue.9',
      });
    },
  });

  return {
    isLoading,
    send: () => {
      if (!api || !config) {
        return;
      }

      send([
        api.tx.system.remark(
          JSON.stringify({
            id,
            chainName: chainData?.chainName,
            address,
            action: 'register',
          }),
        ),
        api.tx.balances.transferKeepAlive(
          '5DZrSMWxtJ5SoYDKmbTQ66V12iAjMryzJW2PS948RmVDtstQ',
          config.fees.register.amount,
        ),
      ]);
    },
  };
}

import {
  ActionButton,
  type ActionButtonProps,
} from '@/components/ActionButton';
import { useCreateAppChainSteps } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainSteps';

export function NextStepButton(props: ActionButtonProps) {
  const { nextStep } = useCreateAppChainSteps();

  return (
    <ActionButton onClick={nextStep} {...props}>
      {'Continue'}
    </ActionButton>
  );
}

import { ActionButton } from '@/components/ActionButton';
import { ConnectWallet } from '@/components/ConnectWallet';
import { NextStepButton } from '@/components/pages/CreateAppChain/NextStepButton';
import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainInfo';
import { ChainBalanceCheck } from '@/components/pages/CreateAppChain/steps/CheckBalancesStep/ChainBalanceCheck';
import { SocialLoginModal } from '@/components/pages/CreateAppChain/steps/CheckBalancesStep/SocialLogin/SocialLoginModal';
import { useIsContactCreated } from '@/components/pages/CreateAppChain/steps/CheckBalancesStep/SocialLogin/state/socialLogin.hooks';
import { StepWrapper } from '@/components/pages/CreateAppChain/steps/StepWrapper';
import { ChainKey } from '@/config';
import { useFaucet } from '@/hooks/api/useFaucet';
import {
  useFreeUserParaIds,
  useHasEnoughBalances,
  useIsRegisteredInRelay,
  useIsRegisteredInTanssi,
  useRelayFreeBalance,
  useTanssiFreeBalance,
} from '@/hooks/polkadot/common';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useIsAuthenticated } from '@/state/token';
import { Checkbox, Stack, Text, Tooltip } from '@mantine/core';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

export function CheckBalanceStep() {
  const router = useRouter();
  const { config, relay } = useChainConfig();
  const { data: session } = useSession();
  const paraIds = useFreeUserParaIds(config);
  const balance = useTanssiFreeBalance(config);
  const { send, isLoading } = useFaucet();
  const relayBalance = useRelayFreeBalance();
  const isAuthenticated = useIsAuthenticated();
  const { isContactCreated } = useIsContactCreated();
  const { hasOwnParaId, setHasOwnParaId } = useCreateAppChainInfo();
  const { isRegistered: isRegisteredInRelay } = useIsRegisteredInRelay(relay);
  const { isRegistered: isRegisteredInTanssi } =
    useIsRegisteredInTanssi(config);

  const {
    isLoading: isLoadingBalances,
    hasEnoughBalance,
    hasEnoughTanssiBalance,
    hasEnoughRelayBalance,
    minRelayBalance,
    minTanssiBalance,
  } = useHasEnoughBalances();

  const networksToCheckBalance = [
    {
      name: config.name,
      balance: balance,
      hasEnoughBalance: hasEnoughTanssiBalance,
      testid: 'tanssi-balance',
    },
    {
      name: relay?.name,
      balance: relayBalance,
      hasEnoughBalance: hasEnoughRelayBalance,
      testid: 'relay-balance',
    },
  ];

  const onRequestFunds = () => {
    if (!session || !isContactCreated || config.key !== ChainKey.Flashbox) {
      router.replace({ query: { ...router.query, auth: true } }, undefined, {
        scroll: false,
      });

      return;
    }

    send();
  };

  const shouldShowNextStepButton = hasEnoughBalance && !isLoading;

  return (
    <>
      <SocialLoginModal />
      <StepWrapper>
        <StepWrapper.Title>{'Got Tokens?'}</StepWrapper.Title>
        <StepWrapper.Card>
          {isRegisteredInTanssi ? (
            <Text size={'lg'}>
              {`You've successfully used your ${config.asset.originSymbol} ${
                config.isSymbioticChain
                  ? ''
                  : `and ${relay?.asset.originSymbol}`
              } balance to register your network!`}
            </Text>
          ) : (
            <Text size={'lg'}>
              {'You must have a minimum balance of'}{' '}
              <Text
                size={'lg'}
                c={'tanssiTeal.9'}
                span
                data-testid={'required-tanssi-amount'}
              >
                {`${minTanssiBalance} ${config.asset.originSymbol}`}{' '}
              </Text>
              {!config.isSymbioticChain && (
                <>
                  {'and'}{' '}
                  <Text
                    size={'lg'}
                    c={'tanssiTeal.9'}
                    span
                    data-testid={'required-relay-amount'}
                  >
                    {`${minRelayBalance} ${relay?.asset.originSymbol}`}{' '}
                  </Text>
                </>
              )}
              {'tokens to successfully launch a new network on Tanssi.'}
            </Text>
          )}

          {isAuthenticated && (
            <>
              <Stack mt={30} align={'start'} gap={'sm'}>
                {config.isSymbioticChain ? (
                  <ChainBalanceCheck
                    isRegistered={isRegisteredInTanssi}
                    hasEnoughBalance={hasEnoughTanssiBalance}
                    balance={balance}
                    name={config.name}
                    testId={'tanssi-balance'}
                  />
                ) : (
                  networksToCheckBalance.map((asset) => (
                    <ChainBalanceCheck
                      key={asset.name}
                      isRegistered={isRegisteredInTanssi}
                      hasEnoughBalance={asset.hasEnoughBalance}
                      balance={asset.balance}
                      name={asset.name || ''}
                      testId={asset.testid}
                    />
                  ))
                )}
              </Stack>

              <Tooltip
                label={'No reserved network ID available for this account.'}
                disabled={!!paraIds?.length}
              >
                <Checkbox
                  mt={'xl'}
                  size={'sm'}
                  label={"I've already reserved a Network ID for my network."}
                  checked={hasOwnParaId}
                  onChange={(e) => {
                    setHasOwnParaId(e.currentTarget.checked);
                  }}
                  styles={{
                    body: { alignItems: 'center' },
                    label: { fontSize: 18, color: 'white' },
                  }}
                  disabled={!paraIds?.length || isRegisteredInRelay}
                />
              </Tooltip>
            </>
          )}

          <StepWrapper.Buttons>
            {isAuthenticated ? (
              shouldShowNextStepButton ? (
                <NextStepButton
                  disabled={!hasEnoughBalance}
                  isReady={!isLoadingBalances}
                />
              ) : (
                <ActionButton
                  isReady={!isLoadingBalances}
                  isLoading={isLoading}
                  onClick={onRequestFunds}
                >
                  Request Tokens
                </ActionButton>
              )
            ) : (
              <ConnectWallet />
            )}
          </StepWrapper.Buttons>
        </StepWrapper.Card>
      </StepWrapper>
    </>
  );
}

import { BackButton } from '@/components/BackButton';
import { useCreateAppChainSteps } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainSteps';
import { Center, Paper } from '@mantine/core';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export function StepCard({ children }: Props) {
  const { prevStep } = useCreateAppChainSteps();

  return (
    <>
      <Paper maw={690} p={{ base: 30, xs: 50 }}>
        {children}
      </Paper>
      <Center mt={20}>
        <BackButton onClick={prevStep}>{'Back'}</BackButton>
      </Center>
    </>
  );
}

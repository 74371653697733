import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

const DEFAULT_DAPP_STATIC_FILES_BUCKET_NAME = 'tanssi-static-files';

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    AUTH_SECRET:
      process.env.NODE_ENV === 'production'
        ? z.string().min(1)
        : z.string().optional(),
    AUTH_URL:
      process.env.NODE_ENV === 'production'
        ? z.string().min(1)
        : z.string().optional(),
    NODE_ENV: z.enum(['development', 'test', 'production']),
    GITHUB_PAT: z.string().min(90),
    SENTRY_AUTH_TOKEN: z.string().optional(),
    FAUCET_ACCOUNT: z.string().min(1),
    STAKING_PENDING_OPERATIONS_ACCOUNT: z.string().min(1),
    TANSSI_BINARY_API_URL: z.string().url(),
    SLACK_ALARM_URL: z.string().url().optional(),
    SLACK_APP_CHAIN_CREATED_URL: z.string().url().optional(),
    DAPP_AWS_REGION: z.string().min(1).default('eu-central-1'),
    DAPP_AWS_ACCESS_KEY_ID: z.string().min(20),
    DAPP_AWS_SECRET_ACCESS_KEY: z.string().min(40),
    DAPP_DATABASE_NAME: z.string().optional(),
    DAPP_STATIC_FILES_BUCKET_NAME: z
      .string()
      .min(3)
      .max(63)
      .default(DEFAULT_DAPP_STATIC_FILES_BUCKET_NAME),
    SENDGRID_DESTROYED_TEMPLATE_ID: z.string().min(1),
    SENDGRID_LAUNCHED_TEMPLATE_ID: z.string().min(1),
    GOOGLE_CLIENT_ID: z.string().min(1),
    GITHUB_ID: z.string().min(1),
    AWS_APP_ID: z.string().min(1),
    // https://nextjs.org/telemetry
    NEXT_TELEMETRY_DISABLED: z.enum(['0', '1']).default('1'),
  },

  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_LOCAL_NODE: z.coerce.boolean().optional().default(false),
    NEXT_PUBLIC_DEPLOYMENT: z
      .enum(['local', 'test', 'sandbox', 'production'])
      .optional()
      .default('local'),
    NEXT_PUBLIC_CAPTCHA_SITE_KEY: z.string().min(1),
    NEXT_PUBLIC_FLAGSMITH_ENV_ID: z.string().min(1),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtime (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    AUTH_SECRET: process.env.AUTH_SECRET,
    AUTH_URL: process.env.AUTH_URL,
    NODE_ENV: process.env.NODE_ENV,
    GITHUB_PAT: process.env.GITHUB_PAT,
    SENTRY_AUTH_TOKEN: process.env.SENTRY_AUTH_TOKEN,
    FAUCET_ACCOUNT: process.env.FAUCET_ACCOUNT,
    STAKING_PENDING_OPERATIONS_ACCOUNT:
      process.env.STAKING_PENDING_OPERATIONS_ACCOUNT,
    TANSSI_BINARY_API_URL: process.env.TANSSI_BINARY_API_URL,
    SLACK_APP_CHAIN_CREATED_URL: process.env.SLACK_APP_CHAIN_CREATED_URL,
    SLACK_ALARM_URL: process.env.SLACK_ALARM_URL,
    NEXT_PUBLIC_LOCAL_NODE: process.env.NEXT_PUBLIC_LOCAL_NODE,
    NEXT_PUBLIC_FLAGSMITH_ENV_ID: process.env.NEXT_PUBLIC_FLAGSMITH_ENV_ID,
    NEXT_PUBLIC_DEPLOYMENT: process.env.NEXT_PUBLIC_DEPLOYMENT,
    NEXT_TELEMETRY_DISABLED: process.env.NEXT_TELEMETRY_DISABLED,
    DAPP_AWS_REGION: process.env.DAPP_AWS_REGION,
    DAPP_AWS_ACCESS_KEY_ID: process.env.DAPP_AWS_ACCESS_KEY_ID,
    DAPP_AWS_SECRET_ACCESS_KEY: process.env.DAPP_AWS_SECRET_ACCESS_KEY,
    DAPP_DATABASE_NAME: process.env.DAPP_DATABASE_NAME,
    DAPP_STATIC_FILES_BUCKET_NAME: process.env.DAPP_STATIC_FILES_BUCKET_NAME,
    SENDGRID_DESTROYED_TEMPLATE_ID: process.env.SENDGRID_DESTROYED_TEMPLATE_ID,
    SENDGRID_LAUNCHED_TEMPLATE_ID: process.env.SENDGRID_LAUNCHED_TEMPLATE_ID,
    GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
    GITHUB_ID: process.env.GITHUB_ID,
    NEXT_PUBLIC_CAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY,
    AWS_APP_ID: process.env.AWS_APP_ID,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});

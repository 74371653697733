import { OffsiteLink } from '@/components/OffsiteLink';
import { Center, Group, Text } from '@mantine/core';
import NextImage from 'next/image';
import classes from './SidebarButton.module.css';

export interface SidebarLink {
  name: string;
  description: string;
  logo: string;
  url?: string;
}

interface SidebarLinkProps extends SidebarLink {
  isDisabled?: boolean;
}

export function SidebarOffsiteLink({
  name,
  description,
  logo,
  url,
  isDisabled,
}: SidebarLinkProps) {
  if (!url) {
    return null;
  }

  return (
    <OffsiteLink
      py={10}
      px={'md'}
      url={url}
      isWrapper
      withIcon={false}
      className={classes.button}
    >
      <Group align={'center'} gap={'xs'}>
        {logo && (
          <Center w={25}>
            <NextImage alt={`${name} logo`} src={logo} width={22} height={22} />
          </Center>
        )}
        <Text size={'lg'} opacity={isDisabled ? 0.5 : 1}>
          {name}
        </Text>
      </Group>
      <Group
        mt={3}
        justify={'space-between'}
        align={'end'}
        wrap={'nowrap'}
        opacity={isDisabled ? 0.5 : 1}
      >
        <Text size={'xs'} c={'gray.6'}>
          {description}
        </Text>
      </Group>
    </OffsiteLink>
  );
}

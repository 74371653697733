import {
  Step,
  TemplateOption,
  type Templates,
} from '@/components/pages/CreateAppChain/state/create.interfaces';
import type {
  EvmFormData,
  SubstrateFormData,
} from '@/server/api/routers/file/file.interfaces';

import { AppchainCategory } from '@/shared/db/entities/appchains';
import { theme } from '@/theme';
import { CheckBalanceStep } from '../steps/CheckBalancesStep';
import { ChooseTemplateStep } from '../steps/ChooseTemplateStep';
import { GenerateFilesStep } from '../steps/GenerateFilesStep';
import { RegisterChainStep } from '../steps/RegisterChainStep';
import { ReserveParaIdStep } from '../steps/ReserveParaIdStep';

export const STEPS = [
  { name: Step.Template, component: <ChooseTemplateStep /> },
  { name: Step.Balances, component: <CheckBalanceStep /> },
  { name: Step.ParaID, component: <ReserveParaIdStep /> },
  { name: Step.Files, component: <GenerateFilesStep /> },
  { name: Step.Register, component: <RegisterChainStep /> },
];

export const TEMPLATES: Templates = [
  {
    name: TemplateOption.Evm,
    description: 'EVM template based on the Moonbeam codebase',
    logo: 'logo_ethereum_light.svg',
    color: theme.colors?.tanssiTeal?.[9] ?? '',
  },
  {
    name: TemplateOption.Substrate,
    description: 'Substrate template with standard specifications',
    logo: 'logo_substrate_light.svg',
    color: theme.other?.colors?.orange ?? '',
  },
  {
    name: TemplateOption.Custom,
    description: 'Custom template with your own specifications',
    logo: '/icons/json-light-icon.svg',
    color: theme.other?.colors?.yellow ?? '',
  },
];

export const INITIAL_TEMPLATE_VALUES: {
  [TemplateOption.Evm]: EvmFormData;
  [TemplateOption.Substrate]: SubstrateFormData;
} = {
  [TemplateOption.Evm]: {
    chainName: '',
    category: '',
    appchainUrl: '',
    x: '',
    isEthereum: true,
    evmChainId: '',
    tokenDecimals: 18,
    tokenSymbol: '',
    sudoAddress: '',
    sudoBalance: '',
    balances: [],
    precompiles: [],
    baseFeePerGas: 1,
    elasticity: 12.5,
  },
  [TemplateOption.Substrate]: {
    chainName: '',
    category: '',
    appchainUrl: '',
    x: '',
    isEthereum: false,
    tokenDecimals: '',
    tokenSymbol: '',
    sudoAddress: '',
    sudoBalance: '',
    ss58Format: '',
    balances: [],
  },
};

export const INITIAL_TEMPLATE_TEST_VALUES: {
  [TemplateOption.Evm]: EvmFormData;
  [TemplateOption.Substrate]: SubstrateFormData;
} = {
  [TemplateOption.Evm]: {
    chainName: 'Test',
    category: AppchainCategory.Infrastructure,
    isEthereum: true,
    evmChainId: '12345',
    tokenDecimals: 18,
    tokenSymbol: 'TEST',
    sudoAddress: '0xeF46c7649270C912704fB09B75097f6E32208b85',
    sudoBalance: '100000',
    balances: [],
    precompiles: [],
    baseFeePerGas: 1,
    elasticity: 12.5,
  },
  [TemplateOption.Substrate]: {
    chainName: 'Test',
    category: AppchainCategory.Infrastructure,
    isEthereum: false,
    tokenDecimals: '12',
    tokenSymbol: 'TEST',
    ss58Format: '42',
    balances: [],
    sudoAddress: '5Fpat4SsY6z1WZRdtEtDXKF2BXbdQ2icrX36hGnGB6BdxZQy',
    sudoBalance: '100000',
  },
};

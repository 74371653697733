import type { TemplateOption } from '@/components/pages/CreateAppChain/state/create.interfaces';
import type { ChainData } from '@/server/api/routers/file/file.interfaces';
import { useAtom } from 'jotai';
import { chainDataAtom, selectedTemplateAtom } from '../create.atoms';

export interface UseChainDataState {
  selectedTemplate: TemplateOption | undefined;
  setSelectedTemplate: (selected: TemplateOption) => void;
  chainData: ChainData | undefined;
  setChainData: (chainData: ChainData) => void;
}

export function useUserChainDataState(): UseChainDataState {
  const [chainData, setChainData] = useAtom(chainDataAtom);
  const [selectedTemplate, setSelectedTemplate] = useAtom(selectedTemplateAtom);

  return {
    selectedTemplate,
    setSelectedTemplate,
    chainData,
    setChainData,
  };
}

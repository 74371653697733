import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainInfo';
import { usePracticeCreateAppchain } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppchainProtectedKey';
import type { ChainConfig } from '@/config';
import { AppChainStatus, useAppchainStatus } from '@/hooks/polkadot/appchain';
import { useIsAppchainProtected } from '@/hooks/useIsAppchainProtected';
import { isDefined } from '@/utils/common';

export function useIsRegisteredInTanssi(config: ChainConfig): {
  isRegistered: boolean;
  isLoading: boolean;
} {
  const { paraId } = useCreateAppChainInfo();
  const { status } = useAppchainStatus(paraId, config);
  const { isProtected, isLoading } = useIsAppchainProtected();
  const { isRegisteredInTanssi } = usePracticeCreateAppchain();

  if (!isProtected) {
    return {
      isLoading: isLoading,
      isRegistered: isRegisteredInTanssi,
    };
  }

  return {
    isLoading,
    isRegistered: isDefined(status) && status !== AppChainStatus.NotFound,
  };
}

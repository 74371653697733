import { useStakingChainConfig } from '@/components/pages/Staking/state/staking.hooks';
import { useIsDancelightEnabledFlag } from '@/hooks/flags/useIsDancelightEnabledFlag';

export function useHandleStakingPath() {
  const { isEnabled: isDancelightEnabled } = useIsDancelightEnabledFlag();
  const { selectedChainKey, config } = useStakingChainConfig();

  return isDancelightEnabled && !selectedChainKey
    ? '/staking'
    : `/${config.key}/staking`;
}

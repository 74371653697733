import { BackButton } from '@/components/BackButton';
import { useCreateAppChainSteps } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainSteps';
import { Box, type BoxProps, Stepper } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { useRouter } from 'next/router';
import classes from './NavCreateAppchain.module.css';

interface Props extends BoxProps {}

export function NavCreateAppchain({ ...props }: Props) {
  const { replace } = useRouter();
  const { activeStep, setActiveStep } = useCreateAppChainSteps();

  return (
    <Box {...props}>
      <BackButton
        mr={'sm'}
        mb={'lg'}
        w={'auto'}
        onClick={() => replace('/create')}
      >
        {'Launch Network'}
      </BackButton>
      <Stepper
        active={activeStep}
        onStepClick={setActiveStep}
        iconSize={55}
        allowNextStepsSelect={false}
        orientation={'vertical'}
        completedIcon={<IconCheck size={40} stroke={2} />}
        classNames={{
          root: classes.root,
          step: classes.step,
          separator: classes.separator,
          stepIcon: classes.stepIcon,
          stepLabel: classes.stepLabel,
          stepDescription: classes.stepDescription,
          verticalSeparator: classes.verticalSeparator,
        }}
      >
        <Stepper.Step
          label={'Template'}
          description={'Pick and modify template'}
        />
        <Stepper.Step label={'Balances'} description={'Verify balances'} />
        <Stepper.Step label={'Network ID'} description={'Reserve network ID'} />
        <Stepper.Step
          label={'Network Data'}
          description={'Generate network data'}
        />
        <Stepper.Step label={'Register'} description={'Register network'} />
      </Stepper>
    </Box>
  );
}

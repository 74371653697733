import type { Vec, u128 } from '@polkadot/types-codec';
import type { ITuple } from '@polkadot/types-codec/types';
import type { PalletProxyProxyDefinition } from '@polkadot/types/lookup';
import type { AccountProxy, ProxyType } from './useProxies.interfaces';

export function transformProxies(
  proxies: ITuple<[Vec<PalletProxyProxyDefinition>, u128]>,
): AccountProxy[] {
  return proxies[0].map(({ delegate, proxyType, delay }) => ({
    delegate: delegate.toString(),
    proxyType: proxyType.type as ProxyType,
    delay: delay.toNumber(),
  }));
}

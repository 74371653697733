import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainInfo';
import { useUserChainDataState } from '@/components/pages/CreateAppChain/state/hooks/useUserChainDataState';
import type { RelayChainConfig } from '@/config';
import { useApi } from '@/hooks/polkadot';
import { useNextFreeParaId } from '@/hooks/polkadot/common/queries';
import { useApiBatchTransaction } from '@/hooks/polkadot/useApiBatchTransaction';
import { useAddress } from '@/state/token';
import { showNotification } from '@mantine/notifications';
import { usePracticeAppchainId } from './usePracticeAppchainId';

export function usePracticeReserveParaId(config: RelayChainConfig | undefined) {
  const api = useApi(config?.ws);
  const address = useAddress();
  const nextFreeParaId = useNextFreeParaId(config);
  const id = usePracticeAppchainId();
  const { setParaId } = useCreateAppChainInfo();
  const { chainData } = useUserChainDataState();

  const { isLoading, send } = useApiBatchTransaction(config, {
    onSuccess: () => {
      if (!nextFreeParaId) return;

      setParaId(nextFreeParaId);

      showNotification({
        title: 'Appchain ID successfully reserved',
        message: `Appchain ID with value of ${nextFreeParaId} was successfully reserved.`,
        color: 'tanssiBlue.9',
      });
    },
  });

  return {
    send: () => {
      if (!api || !config) return;

      send([
        api.tx.system.remark(
          JSON.stringify({
            id,
            chainName: chainData?.chainName,
            address,
            action: 'reserve',
          }),
        ),
        api.tx.balances.transferKeepAlive(
          '5DZrSMWxtJ5SoYDKmbTQ66V12iAjMryzJW2PS948RmVDtstQ',
          config.fees.reserveParaId.amount,
        ),
      ]);
    },
    isLoading,
  };
}

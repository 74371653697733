import { Step } from '@/components/pages/CreateAppChain/state/create.interfaces';
import { useCreateAppChainInfo } from '@/components/pages/CreateAppChain/state/hooks/useCreateAppChainInfo';
import { useUserChainDataState } from '@/components/pages/CreateAppChain/state/hooks/useUserChainDataState';
import { useApiNotifications } from '@/hooks/notifications/useApiNotifications';
import { useCreateAppchainLogger } from '@/hooks/register/useCreateAppchainLogger';
import { useChainConfig } from '@/hooks/useChainConfig';
import { useCreateAppchainFiles } from '@/hooks/useCreateAppchainFiles';
import { AppchainCategory } from '@/shared/db/entities/appchains';
import { useTokenInfo } from '@/state/token';
import { api } from '@/trpc/react';
import { useSession } from 'next-auth/react';

export function useCreateAppchain() {
  const { config } = useChainConfig();
  const userAccount = useTokenInfo();
  const { paraId } = useCreateAppChainInfo();
  const { chainData } = useUserChainDataState();
  const { data: session } = useSession();
  const { specRaw } = useCreateAppchainFiles();
  const { logInfo } = useCreateAppchainLogger();
  const { clientError } = useApiNotifications();

  const { isPending, mutate } = api.appchains.create.useMutation({
    onError: (error) =>
      clientError('Unable to create network: missing data', {
        error,
        paraId,
        chainData,
        session,
        chain: config.name,
      }),
    onSuccess: () => {
      logInfo('Appchain item was successfully created', Step.Register, {
        appchainUrl: chainData?.appchainUrl,
        x: chainData?.x,
        email: session?.user?.email,
        userName: session?.user?.name,
        iconImageBase64: chainData?.iconImage?.content,
      });
    },
  });

  return {
    isPending,
    createAppchainItem: () => {
      if (!userAccount || !paraId) {
        clientError('Unable to create network: missing data', {
          chain: config.name,
          paraId,
          chainData,
          session,
        });

        return;
      }

      mutate({
        paraId,
        name: specRaw?.name || paraId.toString(),
        url: chainData?.appchainUrl || undefined,
        x: chainData?.x || undefined,
        chainKey: config.key,
        category: chainData?.category || AppchainCategory.Other,
        // TODO: add telegram
        telegram: undefined,
        userName: session?.user?.name || undefined,
        email: session?.user?.email || undefined,
        iconImageBase64: chainData?.iconImage?.content,
        symbol: chainData?.tokenSymbol || undefined,
        isEvm: chainData?.isEthereum || undefined,
        evmChainId: chainData?.isEthereum
          ? chainData.evmChainId.toString()
          : undefined,
      });
    },
  };
}
